let pageswitch = document.getElementsByClassName("switch");
let cardclass = "bu-card";
let card = document.getElementsByClassName(cardclass);

let sides = Array.from(pageswitch).map( function (value, key, array) {
	return value.getAttribute('data');
});

Array.prototype.forEach.call(pageswitch, item => {
  item.addEventListener('click', function () {
    if(item.hasAttribute('data')) {
      card[0].classList.toggle('turn');
      card[0].classList.remove( ...sides );
      card[0].classList.add( item.getAttribute('data') );
    }
  });
});